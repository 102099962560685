export default {
  darkGrey: "#2F2F33",
  charcoal: "#1E1E1E",
  brightRed: "#FF3568",
  blue: "#2926CF",
  green: "#A3F936",
  yellow: "#FFCB45",
  purple: "#9086E2",
  offWhite: "#F4F4F4",
  cryptoWhite: "#FBFBFB",
  gold: "linear-gradient(248.45deg, #DEBA5D 5.7%, #F8DE9D 34.88%, #DFB956 53.58%)",
}

export const Levis = {
  redDark: "#AA1F2E",
  redLight: "#FFEFF1",
  blueDark: "#00AEEF",
  blueLight: "#EFFBFF",
}
